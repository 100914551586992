/**
 * @name: 用户管理-用户信息管理接口文件
 * @author: itmobai
 * @date: 2024-07-18 14:45
 * @description： 用户管理-用户信息管理接口文件
 * @update: 2024-07-18 14:45
 */
import api from '@/request/api'
import {IPageRes} from "@/apis/page";
import { IUserParams, IUser } from './types'

export const userPageApi = (params: IUserParams) => api.get<IPageRes<IUser[]>>('/admin/user/queryByPage', params)

export const userExportApi = (params: IUserParams) => api.get('/admin/user/userInfoExport', params, 'blob')
