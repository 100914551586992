
/**
 * @name: 用户管理-用户信息管理
 * @author: itmobai
 * @date: 2024-07-18 14:45
 * @description： 用户管理-用户信息管理
 * @update: 2024-07-18 14:45
 */
import { Vue, Component } from "vue-property-decorator"
import {
  userPageApi,
  userExportApi
} from '@/apis/user/list'
import { IUser, IUserParams } from '@/apis/user/list/types'
import type { ICrudOption } from "@/types/m-ui-crud"
import { deepCopy, exportFile } from '@/utils/common'
import config from '@/config'

@Component({})
export default class userList extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IUser[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IUserParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<IUser> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: '昵称',
        prop: 'nickName',
        align: 'center'
      },
      {
        label: '用户',
        prop: 'userKeywords',
        search: true,
        placeholder: '昵称/手机号',
        hide: true
      },
      {
        label: '头像',
        prop: 'avatarUrl',
        align: 'center',
        type: 'image',
        imgPrefix: config.downloadUrl
      },
      {
        label: '手机号',
        prop: 'phone',
        align: 'center'
      },
      {
        label: '首次授权登录时间',
        prop: 'firstLoginTime',
        align: 'center',
        type: 'daterange',
        search: true
      }
    ]
  }

  getList () {
    this.tableLoading = true

    const query: IUserParams = deepCopy(this.queryParam)
    if (query.firstLoginTime && query.firstLoginTime.length === 2) {
      query.firstLoginTimeStart = query.firstLoginTime[0]
      query.firstLoginTimeEnd = query.firstLoginTime[1]
    } else {
      query.firstLoginTimeStart = ''
      query.firstLoginTimeEnd = ''
    }
    delete query.firstLoginTime

    userPageApi(query).then(e => {
      if (e && e.data) {
        this.tableLoading = false
        this.tableData = e.data.list
        this.tableTotal = e.data.total
      }
    })
  }

  exportExcel () {
    const query: IUserParams = deepCopy(this.queryParam)
    if (query.firstLoginTime && query.firstLoginTime.length === 2) {
      query.firstLoginTimeStart = query.firstLoginTime[0]
      query.firstLoginTimeEnd = query.firstLoginTime[1]
    } else {
      query.firstLoginTimeStart = ''
      query.firstLoginTimeEnd = ''
    }
    delete query.firstLoginTime

    userExportApi(query).then(e => {
      if (e && e.data) {
        exportFile(e.data, '用户信息.xlsx')
        this.$message.success('导出成功!')
      }
    })
  }

  created () {
    this.getList()
  }
}
